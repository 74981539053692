import Vue from "vue";
import Vuex from "vuex";
import "es6-promise/auto";
import commonData from "@/store/commonData.js";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        //
    },
    state: {
        commonData: commonData, // from commonData.js
    },
    getters: {
        //
    },
    actions: {
        //
    },
    mutations: {
        //
    },
});