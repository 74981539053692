import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import Vuex from "vuex";
import i18n from "./i18n";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueLazyLoad from "vue-lazyload";

Vue.config.productionTip = false;

const serviceUrl = process.env.VUE_APP_SERVICE_URL;
console.log("VUE_APP_SERVICE_URL = " + serviceUrl);

Vue.prototype.$serviceUrl = serviceUrl;
axios.defaults.withCredentials = false;

axios.defaults.baseURL = serviceUrl;
Vue.prototype.$axios = axios;

Vuex.Store.prototype.$axios = axios;

Vue.use(require("vue-moment"));

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

Vue.use(VueToast);

Vue.use(VueReCaptcha, { siteKey: "6Lf4YVcaAAAAAPwAIA1e17EcKn5-Cfej4Cioo8Qm" });

Vue.use(VueLazyLoad);
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");