<template>
    <div>
        <h1>Galéria</h1>

        <ul class="mt-4 p-0 text-center">
            <li v-for="(image, index) in media" :key="index" class="d-inline-block">
                <img v-lazy="image.src || image.thumb" class="m-1 c-pointer" style="height: 125px" @click="openGallery(index)" />
            </li>
        </ul>
        <LightBox ref="lightbox" :media="media" :showLightBox="false" :showThumbs="false"></LightBox>
    </div>
</template>

<script>
import { Utils } from "@/mixins/Utils";
import LightBox from "vue-image-lightbox";

export default {
    name: "Gallery",
    mixins: [Utils],
    components: {
        LightBox,
    },

    data() {
        return {
            media: [
                // CAROUSEL
                {
                    src: require("../assets/images/carousel/1.jpg"),
                },
                {
                    src: require("../assets/images/carousel/2.jpg"),
                },
                {
                    src: require("../assets/images/carousel/3.jpg"),
                },
                {
                    src: require("../assets/images/carousel/4.jpg"),
                },
                {
                    src: require("../assets/images/carousel/5.jpg"),
                },
                {
                    src: require("../assets/images/carousel/6.jpg"),
                },
                {
                    src: require("../assets/images/carousel/7.jpg"),
                },

                // GALLERY
                {
                    src: require("../assets/images/gallery/1.jpg"),
                },
                {
                    src: require("../assets/images/gallery/2.jpg"),
                },
                {
                    src: require("../assets/images/gallery/3.jpg"),
                },
                {
                    src: require("../assets/images/gallery/4.jpg"),
                },
                {
                    src: require("../assets/images/gallery/5.jpg"),
                },
                {
                    src: require("../assets/images/gallery/6.jpg"),
                },
                {
                    src: require("../assets/images/gallery/7.jpg"),
                },
                {
                    src: require("../assets/images/gallery/8.jpg"),
                },
                {
                    src: require("../assets/images/gallery/9.jpg"),
                },
                {
                    src: require("../assets/images/gallery/10.jpg"),
                },
                {
                    src: require("../assets/images/gallery/11.jpg"),
                },
                {
                    src: require("../assets/images/gallery/12.jpg"),
                },
                {
                    src: require("../assets/images/gallery/13.jpg"),
                },
            ],
        };
    },

    mounted() {
        //
    },

    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index);
        },
    },
};
</script>

<style lang="scss" scoped></style>
