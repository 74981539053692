<template>
    <form @submit="recaptcha" @change="checkForm()" novalidate="true" class="mt-4 text-start">
        <div class="row g-3">
            <div class="col-md-4">
                <label for="firstName" class="form-label"><span class="text-warning me-1">*</span>Meno</label>
                <input
                    v-model="form.firstName"
                    type="text"
                    :class="{ 'is-invalid': errors.firstName, 'is-valid': submitted && !errors.firstName }"
                    class="form-control"
                    id="firstName"
                />
                <div class="invalid-feedback">{{ errors.firstName }}</div>
            </div>

            <div class="col-md-4">
                <label for="lastName" class="form-label"><span class="text-warning me-1">*</span>Priezvisko</label>
                <input
                    v-model="form.lastName"
                    type="text"
                    :class="{ 'is-invalid': errors.lastName, 'is-valid': submitted && !errors.lastName }"
                    class="form-control"
                    id="lastName"
                />
                <div class="invalid-feedback">{{ errors.lastName }}</div>
            </div>

            <div class="col-md-4">
                <label for="email" class="form-label"><span class="text-warning me-1">*</span>E-mail</label>
                <input
                    v-model="form.email"
                    type="email"
                    :class="{ 'is-invalid': errors.email, 'is-valid': submitted && !errors.email }"
                    class="form-control"
                    id="email"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="col-12">
                <label for="message" class="form-label"><span class="text-warning me-1">*</span>Správa</label>
                <textarea
                    v-model="form.message"
                    :class="{ 'is-invalid': errors.message, 'is-valid': submitted && !errors.message }"
                    class="form-control"
                    id="message"
                    rows="5"
                ></textarea>
                <div class="invalid-feedback">{{ errors.message }}</div>
            </div>

            <div class="col-12 mt-4">
                <div class="form-check">
                    <input
                        v-model="form.gdpr"
                        type="checkbox"
                        :class="{ 'is-invalid': errors.gdpr, 'is-valid': submitted && !errors.gdpr }"
                        class="form-check-input"
                        id="gdpr"
                    />
                    <label class="form-check-label" for="gdpr">
                        Súhlas so spracovaním osobných údajov pre potreby spracovania dát z formulára.
                    </label>
                    <div class="invalid-feedback">{{ errors.gdpr }}</div>
                </div>
            </div>

            <div class="col-12">
                <button
                    class="btn btn-secondary"
                    type="submit"
                    :disabled="
                        (Object.keys(form).length === 0 && form.constructor === Object) ||
                            (Object.keys(errors).length !== 0 && errors.constructor === Object) ||
                            submitted
                    "
                >
                    Odoslať
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "Header",
    mixins: [Utils],

    data() {
        return {
            form: {},
            errors: {},
            submitted: false,
        };
    },

    mounted() {
        //
    },

    methods: {
        resetForm() {
            this.form = {};
        },

        async recaptcha(e) {
            e.preventDefault();
            this.submitted = true;

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha("contact_form");

            const formData = new FormData();
            formData.append("recaptcha_response", token);
            formData.append("email", this.form.email);
            formData.append("name", this.form.firstName + " " + this.form.lastName);
            formData.append("message", this.form.message);

            const validate = this.checkForm();
            if (validate) {
                this.$axios
                    .post("send-mail.php", formData)
                    .then((response) => {
                        console.log(response);

                        this.$toast.success("Správa bola úspešne odoslaná");
                        this.resetForm();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Správa nebola odoslaná");
                    })
                    .finally(() => {
                        this.submitted = false;
                    });
            }
        },

        checkForm() {
            this.errors = {};

            if (!this.form.firstName) {
                this.errors["firstName"] = "Toto pole je povinné!";
            }
            if (!this.form.lastName) {
                this.errors["lastName"] = "Toto pole je povinné!";
            }
            if (!this.form.email) {
                this.errors["email"] = "Toto pole je povinné!";
            } else if (!this.validEmail(this.form.email)) {
                this.errors["email"] = "E-mail nie je validný!";
            }
            if (!this.form.message) {
                this.errors["message"] = "Toto pole je povinné!";
            }
            if (!this.form.gdpr || this.form.gdpr === false) {
                this.errors["gdpr"] = "Toto pole je povinné!";
            }

            if (Object.keys(this.errors).length === 0 && this.errors.constructor === Object) {
                return true;
            } else {
                return false;
            }
        },
        validEmail(email) {
            var re = /.+@.+\..+/;
            return re.test(email);
        },
    },
};
</script>

<style lang="scss" scoped></style>
