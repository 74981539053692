<template>
    <section class="text-center">
        <div class="container">
            <div class="row shadow">
                <div v-for="(item, index) of items" :key="index" class="col-md item p-0">
                    <a href="javascript:void(0)" @click="$route.path !== item.link ? $router.push(item.link) : $scrollTo('#app', 0)" class="item-link">
                        <i :class="item.icon + ' fa-3x mt-3'"></i>
                        <h4 class="mt-4">{{ item.title }}</h4>
                        <p class="small mt-3 text-secondary" v-html="item.description"></p>
                        <img class="img-float" src="../../assets/images/koruna.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "SectionServices",
    mixins: [Utils],

    props: ["phone"],

    data() {
        return {
            items: [
                {
                    link: "/kontakt",
                    icon: "fas fa-compass",
                    title: "Poradíme Vám",
                    description: "Ponúkame Vám odborné poradenstvo pri návrhu nábytku na mieru.",
                },
                {
                    link: "/galeria",
                    icon: "fas fa-tools",
                    title: "Vyrobíme",
                    description: "Vyrobíme akýkoľvek nábytok na mieru podľa Vašich predstáv.",
                },
                {
                    link: "/kontakt",
                    icon: "fas fa-phone",
                    title: "Kontaktujte nás",
                    description: this.phone,
                },
            ],
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
section {
    margin-top: -5rem;
    padding: 0;

    .item {
        background: #e9ebf2;
        background: linear-gradient(135deg, #e9ebf2, #e9ebf2);
        color: #212f3c;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;

        .img-float {
            color: #e7d696;
            font-size: 2rem;
            position: absolute;
            bottom: -2rem;
            left: 0;
            right: 0;
            transition: all 0.7s;
            margin: 0 auto;
        }

        &:hover {
            background: #212f3c;
            background: linear-gradient(135deg, #212f3c 0%, #212f3c 50%, #012f3c 100%);
            color: #fff;

            & .img-float {
                bottom: 0.5rem;
            }
        }

        .item-link {
            text-decoration: none;
            display: block;
            padding: 2rem;
        }
    }
}

@media (max-width: 767.98px) {
    section {
        margin-top: 0;

        .item {
            border-bottom: 1px solid #fff;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}
</style>
