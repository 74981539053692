<template>
    <div>
        <h1>Drevovýroba Rysula</h1>
        <p class="h5">
            <em>
                Naša firma sa zaoberá výrobou a montážou nábytku na mieru v rámci celého Slovenska už od roku 2010. Zhotovujeme
                akýkoľvek drevený produkt nielen pre koncových zákazníkov, ale aj pre rôzne zamerané spoločnosti, hotely či
                napríklad gastro priemysel. Okrem kvalitného nábytku na mieru pre vás pripravíme kompletný štúdiový návrh. Pri
                výrobe používame kvalitné materiály od spoľahlivých dodávateľov. Pracujeme s takmer všetkými dostupnými materiálmi
                a najrôznejšími kovaniami, ktoré priebežne dopĺňame podľa vášho želania a našich skúseností.
            </em>
        </p>
    </div>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "Home",
    mixins: [Utils],

    data() {
        return {
            //
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped></style>
