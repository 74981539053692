import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Gallery from "@/views/Gallery.vue";
import Contact from "@/views/Contact.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Drevovýroba Rysula",
        component: Home,
    },
    {
        path: "/galeria",
        name: "Galéria",
        component: Gallery,
    },
    {
        path: "/kontakt",
        name: "Kontakt",
        component: Contact,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;