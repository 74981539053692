<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md text-center text-md-start">
                    <img class="logo" src="../../assets/images/logo.png" alt="Logo" />
                </div>

                <div class="col-md mt-4 mt-md-0 text-center text-md-start">
                    <h5>Drevovýroba Rysula</h5>
                    <p>
                        SNP 10/106, Liptovský Mikuláš - Palúdzka<br />
                        (Areál bývaleho Ligarexu)
                    </p>

                    <p>
                        <i class="fas fa-phone-alt"></i>{{ phone }}<br /><a :href="'mailto:' + email">{{ email }}</a>
                    </p>
                </div>

                <div class="col-md mt-4 mt-md-0 text-center text-md-start">
                    <div class="mb-4">
                        <a
                            class="facebook-link mx-4 mx-md-2"
                            href="https://www.facebook.com/drevovyrobarysula/"
                            target="_blank"
                            title="Facebook"
                        >
                            <i class="fab fa-facebook-square fa-2x"></i>
                        </a>
                        <a
                            class="instagram-link mx-4 mx-md-2"
                            href="https://www.instagram.com/drevovyroba_rysula/"
                            target="_blank"
                            title="Instagram"
                        >
                            <i class="fab fa-instagram fa-2x"></i>
                        </a>
                    </div>
                    <p class="copyright small">&copy; Drevovýroba Rysula, {{ copyrightYear }}. Všetky práva vyhradené.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "Footer",
    mixins: [Utils],

    props: ["phone", "email"],

    data() {
        return {
            copyrightYear: new Date().getFullYear(),
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
footer {
    padding: 5rem 0;
    background: #212f3c;
    background: linear-gradient(135deg, #212f3c 0%, #212f3c 50%, #012f3c 100%);
    color: #fff;

    img.logo {
        width: 200px;
    }

    h5 {
        color: #e7d696;
    }

    .fa-phone-alt {
        margin-right: 10px;
    }

    .facebook-link,
    .instagram-link {
        color: #e9ebf2;
        transition: all 0.5s;

        &:active,
        &:hover,
        &:focus {
            color: #e7d696;
        }
    }

    .copyright {
    }
}
</style>
