<template>
    <section class="text-center shadow">
        <div class="video-container">
            <div class="video-overlay"></div>
            <video autoplay loop muted>
                <source src="../../assets/videos/video-bg.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>

            <div class="container">
                <h4>
                    Mám rád drevo a starostlivo s ním pracujem. Vyrobím aj upravím čokoľvek z dreva, na mieru podľa Vašich želaní.
                    <small class="d-block mt-3">- Ľubomír Rysula -</small>
                </h4>
            </div>
        </div>
    </section>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "SectionVideo",
    mixins: [Utils],

    data() {
        return {
            //
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
section {
    padding: 0;

    .video-container {
        position: relative;
        width: 100%;
        height: 40rem;
        overflow: hidden;

        .video-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 7rem 0;
            z-index: -1;
            background: rgba(0, 0, 0, 0.5);
        }

        .container {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 7rem 0;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            h4 {
                color: #fff;
                padding: 2rem;
                font-style: italic;
                border: 2px solid rgba(255, 255, 255, 0.5);
                border-left: none;
                border-right: none;
            }
        }

        video {
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
        }
    }
}
</style>
