<template>
    <section class="shadow">
        <div class="contact-overlay">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 text-center text-md-start">
                        <h2>Kontaktujte nás</h2>
                        <h4>Potrebujete poradiť? Neváhajte nás kontaktovať.</h4>
                    </div>
                    <div class="col-md-4 align-self-center text-center">
                        <button
                            @click="
                                $route.path !== '/kontakt' ? $router.push('/kontakt') : $scrollTo('#app', 0)
                            "
                            type="button"
                            class="btn btn-lg btn-outline-light my-3"
                        >
                            Kontaktný formulár
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "SectionContact",
    mixins: [Utils],

    data() {
        return {
            //
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
section {
    padding: 10rem 0;
    background-image: url("../../assets/images/contact.jpg");
    background-size: cover;
    background-position: top right;
    background-attachment: fixed;
    position: relative;

    .contact-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
    }
}

@media (max-width: 767.98px) {
    section {
        background-attachment: scroll;
    }
}
</style>
