<template>
    <div :class="{ 'w-0': !showNavbar, 'w-100': showNavbar }" class="navbar">
        <i v-if="!navbarOpened && showNavbar" class="fas fa-spinner fa-spin fa-lg navbar-loading-icon"></i>

        <div v-if="navbarOpened">
            <div @click="closeNavigation()" class="navbar-close ms-auto mt-4 me-3">
                <i class="fas fa-times fa-2x"></i>
            </div>

            <div class="container">
                <div class="d-block">
                    <h2>Drevovýroba Rysula</h2>
                    <p>Bobrovec 282, 032 21 Bobrovec<br />Slovenská republika</p>

                    <h2>Ľubomír Rysula</h2>
                    <p>{{ phone }}<br />{{ email }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "Navbar",
    mixins: [Utils],

    props: ["open", "phone", "email"],

    data() {
        return {
            showNavbar: false,
            navbarOpened: false,
        };
    },

    mounted() {
        //
    },

    methods: {
        openNavigation() {
            this.showNavbar = true;
            document.body.classList.add("overflow-hidden");
            setTimeout(() => {
                this.navbarOpened = true;
            }, 700);
        },
        closeNavigation() {
            this.showNavbar = false;
            this.navbarOpened = false;
            setTimeout(() => {
                document.body.classList.remove("overflow-hidden");
            }, 700);
        },
    },

    watch: {
        open: function() {
            this.openNavigation();
        },
    },
};
</script>

<style lang="scss" scoped>
.navbar {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 5;
    display: block;
    padding: 5rem 0;
    background: #212f3c;
    background: linear-gradient(135deg, #212f3c 0%, #212f3c 50%, #012f3c 100%);
    color: #fff;
    overflow: auto;
    transition: width 0.7s;

    &.w-0 {
        width: 0 !important;
    }
    &.w-100 {
        width: 100% !important;
    }

    .navbar-loading-icon {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
}
.navbar-close {
    position: absolute;
    top: 0;
    right: 1rem;
    color: #fff;
    transition: all 0.5s;

    &:active,
    &:hover,
    &:focus {
        color: #e7d696;
        cursor: pointer;
    }
}
</style>
