<template>
    <header :class="{ 'header-home': $route.path === '/' }" class="shadow">
        <div class="header-overlay"></div>

        <div
            v-show="$route.path === '/'"
            id="carouselHeader"
            class="carousel slide carousel-fade"
            data-bs-ride="carousel"
            data-bs-pause="false"
        >
            <div class="carousel-inner">
                <div
                    v-for="(item, index) in carouselItems"
                    :key="index"
                    :class="{ active: index === 0 }"
                    class="carousel-item"
                    data-bs-interval="5000"
                >
                    <div class="carousel-image" :style="'background-image: url(\'' + item.image + '\')'"></div>

                    <div v-if="item.image" class="carousel-overlay"></div>

                    <div class="carousel-caption text-start">
                        <h2 v-if="item.title" class="fs-1">{{ item.title }}</h2>
                        <h4 v-if="item.subtitle">{{ item.subtitle }}</h4>
                    </div>
                </div>
            </div>

            <div v-if="carouselItems.length > 1" class="carousel-control-wrapper">
                <a class="carousel-control-prev" href="#carouselHeader" role="button" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselHeader" role="button" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </a>
            </div>
        </div>

        <div class="container">
            <router-link to="/">
                <div class="logo-wrapper p-4 shadow">
                    <img src="../../assets/images/logo.png" alt="Logo" />
                </div>
            </router-link>

            <!--<button
                @click="$emit('open-navbar', true)"
                type="button"
                class="btn btn-lg btn-outline-light btn-sm navbar-open mt-4 mx-4 mx-md-0"
            >
                <i class="fas fa-bars fa-2x my-1 mx-3"></i>
            </button>-->

            <ul class="navigation-menu">
                <li><router-link :to="'/'">Úvodná stránka</router-link></li>
                <li><router-link :to="'/galeria'">Galéria</router-link></li>
                <li><router-link :to="'/kontakt'">Kontakt</router-link></li>
            </ul>
        </div>
    </header>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "Header",
    mixins: [Utils],

    data() {
        return {
            carouselItems: [
                { image: require("../../assets/images/carousel/1.jpg"), title: "", subtitle: "" },
                { image: require("../../assets/images/carousel/2.jpg"), title: "", subtitle: "" },
                { image: require("../../assets/images/carousel/3.jpg"), title: "", subtitle: "" },
                { image: require("../../assets/images/carousel/4.jpg"), title: "", subtitle: "" },
                { image: require("../../assets/images/carousel/5.jpg"), title: "", subtitle: "" },
                { image: require("../../assets/images/carousel/6.jpg"), title: "", subtitle: "" },
                { image: require("../../assets/images/carousel/7.jpg"), title: "", subtitle: "" },
            ],
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
.navbar-open {
    float: right;
}

header {
    background: #e9ebf2;
    background: linear-gradient(125deg, #212f3c 0%, #e9ebf2 20%, #212f3c 100%);
    background-image: url("../../assets/images/header.jpg");
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;
    overflow: hidden;

    &.header-home {
        height: 40rem;
        min-height: 100vh;
    }

    .header-overlay,
    .carousel-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .container {
        position: relative;

        .logo-wrapper {
            display: inline-block;
            background: rgba(33, 47, 60, 0.95);
            width: 250px;
            max-width: 50%;
            z-index: 0;
        }

        .navigation-menu {
            display: inline-block;
            list-style-type: none;
            padding: 2rem;
            li {
                display: inline-block;
                margin: 1rem;
                a {
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 0.75rem 1rem;
                    border: 2px solid transparent;
                    transition: all 0.5s;
                    &.router-link-exact-active.router-link-active {
                        border-color: #e7d696;
                    }
                    &:hover,
                    &:focus {
                        border-color: #fff;
                    }
                }
            }
        }
    }

    .carousel-control-wrapper {
        position: absolute;
        bottom: calc(20% - 2.5rem);
        right: 5%;
        width: 100px;
        height: 2.5rem;

        .carousel-control-next,
        .carousel-control-prev {
            width: auto !important;
            opacity: 1 !important;
            transition: background-color 0.3s;
        }

        .carousel-control-next:hover,
        .carousel-control-prev:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    .carousel {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        .carousel-inner {
            width: 100%;
            height: 100%;

            .carousel-item {
                width: 100%;
                height: 100%;
                z-index: 1;

                .carousel-image {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center center;
                }

                @keyframes zoom {
                    from {
                        transform: scale(1, 1);
                    }
                    to {
                        transform: scale(1.1, 1.1);
                    }
                }
                &.active .carousel-image {
                    animation: zoom 4s forwards;
                }

                .carousel-caption {
                    bottom: 50px;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .navigation-menu {
        display: block !important;
        li {
            display: block !important;
            margin: 2rem 0 !important;
        }
    }
}
</style>
