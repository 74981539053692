<template>
    <section class="text-center">
        <div class="container">
            <img src="../../assets/images/koruna.png" alt="" />
            <h5 class="mt-3">
                <em>
                    Veríme, že naše dlhoročné skúsenosti, odborné vedomosti a množstvo vyrobených produktov najrôznejšieho druhu z
                    nás robia vášho seriózneho partnera.
                </em>
            </h5>
        </div>

        <div class="partners-wrap">
            <div class="container">
                <h2>Realizácie</h2>
                <div class="partners">
                    <div v-for="(item, index) of partners" :key="index" class="partner-item">
                        <a :href="item.url" :title="item.title" target="_blank">
                            <img :src="item.image" :alt="item.title" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Utils } from "@/mixins/Utils";

export default {
    name: "SectionAboutUs",
    mixins: [Utils],

    data() {
        return {
            partners: [
                {
                    url: "https://www.kastielpalffy.sk/",
                    title: "Kaštieľ Pálffy",
                    image: require("../../assets/images/partners/kastielpalffy.png"),
                },
                {
                    url: "https://usadlostpodkrivanom.sk/",
                    title: "Usadlosť pod Kriváňom",
                    image: require("../../assets/images/partners/usadlostpodkrivanom.png"),
                },
                {
                    url: "https://www.penzionmaria.eu/sk",
                    title: "Penzión Mária",
                    image: require("../../assets/images/partners/penzionmaria.png"),
                },
                {
                    url: "https://www.vmomente.sk/",
                    title: "Moment",
                    image: require("../../assets/images/partners/moment.png"),
                },
                {
                    url: "https://www.hotellomnica.sk/",
                    title: "Hotel Lomnica",
                    image: require("../../assets/images/partners/hotellomnica.png"),
                },
            ],
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
section {
    padding: 7rem 0;
    .partners-wrap {
        clip-path: polygon(50% 0%, 83% 12%, 100% 10%, 100% 100%, 68% 100%, 32% 100%, 0 100%, 0 10%, 17% 12%);
        background: linear-gradient(180deg, rgba(#e7d696, 0.25), transparent);
        margin: 7rem 0 0;
        padding-top: 5rem;
        h2 {
            color: #16303d;
        }
        .partners {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .partner-item {
                padding: 3.5rem 2.5rem 0;
                width: 20%;
                a {
                    width: 100%;
                    img {
                        width: 100%;
                        filter: grayscale(1);
                        opacity: 0.8;
                        transition: all 0.3s;
                    }
                    &:hover,
                    &:focus {
                        img {
                            opacity: 1;
                            filter: grayscale(0);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1199.98px) {
    .partners {
        .partner-item {
            width: 25% !important;
        }
    }
}

@media (max-width: 991.98px) {
    .partners {
        .partner-item {
            width: 33.3333333333% !important;
        }
    }
}

@media (max-width: 767.98px) {
    .partners {
        .partner-item {
            width: 50% !important;
        }
    }
}

@media (max-width: 575.98px) {
    .partners {
        .partner-item {
            width: 60% !important;
        }
    }
}
</style>
